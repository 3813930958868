<app-top-bar title="ORDER.TITLE"></app-top-bar>
<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
<div class="bill">
  <div class="grow">

     <!-- Billed orders AKA unpaid -->
    <div *ngIf="billedOrders?.length > 0">
      <app-order-list [orders]="billedOrders"></app-order-list>

      <!-- subtotal (only items without fees and vouchers) -->
      <div class="total" *ngIf="subtotal > 0">
        <div class="row">
          <span class="label">{{ 'ORDER.SUBTOTAL' | translate }}</span>
          <span class="amount" *ngIf="subtotal > 0">
            {{ subtotal | currencySpace }}
            <div class="default-currency">
              {{ subtotal | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Normal Voucher -->
      <div class="total" *ngIf="discountAmount > 0">
        <div class="row voucher">
          <span class="label">{{ 'VOUCHER.VOUCHER' | translate }}</span>
          <span class="amount">
            {{ -discountAmount | currencySpace }}
            <div class="default-currency">
              {{ -discountAmount | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Fees -->
      <div class="total" *ngIf="fees?.length > 0">
        <div class="row voucher" *ngFor="let fee of fees">
          <span class="label ">
            {{ fee.name }}
          </span>
          <span class="amount">
            {{ fee.amount | currencySpace }}
            <div class="default-currency">
              {{ fee.amount | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Final total in case of fees or vouchers -->
      <div class="total" *ngIf="discountAmount > 0 || feesAmount > 0">
        <div class="row">
          <span class="label">{{ 'ORDER.TOTAL' | translate }}</span>
          <span class="amount">
            {{ finalTotal | currencySpace }}
            <div class="default-currency">
              {{ finalTotal | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Balance Voucher -->
      <div class="total" *ngIf="appliedVoucher?.mode === VoucherMode.Balance && isOrderFree && discountAmount > 0">
        <div class="row voucher">
          <span class="label">{{ 'VOUCHER.REMAINING_BALANCE' | translate }}</span>
          <span class="amount">
            {{ appliedVoucher.amount - subtotal  | currencySpace }}
            <div class="default-currency">
              {{ appliedVoucher.amount - subtotal | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>
    </div>

    <!-- Previous orders AKA paid -->
    <div *ngIf="paidOrders?.length > 0" class="subtitle">
      <span>{{ 'ORDER.PREVIOUS' | translate }}:</span>
      <app-order-list *ngIf="paidOrders?.length > 0" [orders]="paidOrders"></app-order-list>

      <!-- subtotal (only items without fees and vouchers) -->
      <div class="total" *ngIf="paidSubtotal > 0">
        <div class="row">
          <span class="label">{{ 'ORDER.SUBTOTAL' | translate }}</span>
          <span class="amount" *ngIf="paidSubtotal > 0">
            {{ paidSubtotal | currencySpace }}
            <div class="default-currency">
              {{ paidSubtotal | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Normal Voucher -->
      <div class="total" *ngIf="paidDiscountAmount > 0">
        <div class="row voucher">
          <span class="label">{{ 'VOUCHER.VOUCHER' | translate }}</span>
          <span class="amount">
            {{ -paidDiscountAmount | currencySpace }}
            <div class="default-currency">
              {{ -paidDiscountAmount | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Fees -->
      <div class="total" *ngIf="paidFees?.length > 0">
        <div class="row voucher" *ngFor="let fee of paidFees">
          <span class="label ">
            {{ fee.name }}
          </span>
          <span class="amount">
            {{ fee.amount | currencySpace }}
            <div class="default-currency">
              {{ fee.amount | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Final total -->
      <div class="total" *ngIf="paidDiscountAmount > 0 || paidFeesAmount > 0">
        <div class="row" *ngIf="paidOrders">
          <span class="label">{{ 'ORDER.TOTAL' | translate }}</span>
          <span class="amount" *ngIf="paidFinalTotal >= 0">
            {{ paidFinalTotal | currencySpace }}
            <div class="default-currency">
              {{ paidFinalTotal | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>
    </div>

    <app-order-list *ngIf="paidOrders?.length <= 0 && billedOrders?.length <= 0" [orders]="paidOrders"></app-order-list>

  </div>

  <div *ngIf="showPayButton && (orders && orders.length > 0 && finalTotal > 0 && paymentMode !== PaymentMode.Prepaid) && !isOrderFree" class="ask-bill">
    <div class="left" *ngIf="paymentType">
      <h6>{{ 'BILL.PAY_WITH' | translate }}</h6>
      <button class="payment-method-button" (click)="openPaymentTypeModal()">
        <img class="icon" [src]="paymentType.key | paymentTypes | themeSource" />
        <span>{{ paymentType.name }}</span>
        <img [src]="'unfold.svg' | themeSource" />
      </button>
    </div>
    <div class="right">
      <div class="voucher-button" *ngIf="!voucherSubmitted">
        <span class="voucher-text" (click)="openVoucherModal()">{{ 'VOUCHER.ADD' | translate }}</span>
        <img [src]="'voucher.svg' | themeSource">
      </div>
      <div *ngIf="isApplePay" class="applepay-total" >
        <span>
          {{ finalTotal | currencySpace }}
        </span>
        <div id="ckoApplePay" class="apple-pay-button" lang=en (click)="submitApplePay()"></div>
      </div>
      <button *ngIf="!isApplePay" mat-raised-button class="confirm" (click)="submit()" [disabled]="submitted ? 'disabled' : null">
        <span class="strong">{{ 'BILL.PAY_BILL' | translate }}
        </span>&nbsp;
        <span>
          {{ finalTotal | currencySpace }}
        </span>
      </button>
    </div>

  </div>

  <div *ngIf="isOrderFree" class="pay-button-container">
    <button class="pay-button"  mat-raised-button (click)="submit()" [disabled]="submitted ? 'disabled' : null">
      <span>
        {{ 'BILL.PAY_BILL' | translate }}
      </span>
    </button>
  </div>
</div>
