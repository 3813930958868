import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CartComponent } from '../cart/cart.component';
import { EzetapComponent } from '../ezetap/ezetap.component';
import { FiltersComponent } from '../menu/filters/filters.component';
import { PaymentFailedComponent } from '../payment-failed/payment-failed.component';
import { SettingsComponent } from '../settings/settings.component';
import { TotalProcessingComponent } from '../total-processing/total-processing.component';
import { WaiterComponent } from '../waiter/waiter.component';
import { EzeTapSessionDetails,TotalProcessingDetails } from './online-payment.service';
import { PaymentLinkConfirmationComponent } from '../payment-link-confirmation/payment-link-confirmation.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  public openFilters(): void {
    this.dialog.open(FiltersComponent, this.getFullScreenConfig());
  }

  public openLanguages(): void {
    this.dialog.open(SettingsComponent, this.getFullScreenConfig());
  }

  public openCart(): void {
    this.dialog.open(CartComponent, this.getFullScreenConfig());
  }

  public openTP(data: TotalProcessingDetails): void {
    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    const fullScreenConfig = {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['dialog-container', directionClass],
      data
    };

    this.dialog.open(TotalProcessingComponent, fullScreenConfig);
  }

  public openPaymentLinkConfirmation(): void {
    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    const fullScreenConfig = {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['dialog-container', directionClass],
    };

    this.dialog.open(PaymentLinkConfirmationComponent, fullScreenConfig);
  }

  public openEzeTap(data: EzeTapSessionDetails): void {
    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    const fullScreenConfig = {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['dialog-container', directionClass],
      data
    };
    this.dialog.open(EzetapComponent, fullScreenConfig);
  }

  public closeDialogs(): void {
    this.dialog.closeAll();
  }

  public openWaiter(): void {
    this.dialog.open(WaiterComponent, {
      disableClose: true,
      height: '400px',
      width: '300px',
    });
  }

  public openPaymentFailed(): void {
    const dialogRef = this.dialog.open(PaymentFailedComponent, {
      disableClose: false,
      height: '250px',
      width: '300px',
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         dialogRef.close();
      }, 10000);
    });

  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private getFullScreenConfig(): Object {
    const directionClass: string = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';

    const fullScreenConfig = {
      disableClose: true,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['dialog-container', directionClass],
    };

    return fullScreenConfig;
  }
}
