import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface voucherData {
  postpaid?: boolean,
  voucherName?: string
}

@Component({
  selector: 'app-voucher-modal',
  templateUrl: './voucher-modal.component.html',
  styleUrls: ['./voucher-modal.component.scss']
})
export class VoucherModalComponent implements OnInit {

  public formGroup: FormGroup;
  public voucherName: string;
  public isPostpaid = false;

  constructor(
    public dialogRef: MatDialogRef<VoucherModalComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: voucherData,
  ) {
    this.isPostpaid = data.postpaid || false;
  }

  public ngOnInit(): void {
    this.voucherName = this.data.voucherName || '';
    this.formGroup = new FormGroup({
      voucherName: new FormControl(this.voucherName, [Validators.minLength(2)]),
    });
  }

  public validateVoucher(): void {
    if (!this.getVoucherName().valid) {
      return;
    }

    this.dialogRef.close({
      voucherName: this.getVoucherName().value
    });
  }

  private getVoucherName(): FormControl {
    return this.formGroup.get('voucherName') as FormControl;
  }
}
