import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BidiModule } from '@angular/cdk/bidi';
import { ApiInterceptor } from './shared/api-interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { WaiterComponent } from './waiter/waiter.component';
import { BillComponent } from './bill/bill.component';
import { SentryModule } from './shared/sentry';
import { SettingsComponent } from './settings/settings.component';
import { OrderListComponent } from './order-list/order-list.component';
import { CartComponent } from './cart/cart.component';
import { PaymentMethodComponent } from './bill/payment-method/payment-method.component';
import { ChargeToMyRoomComponent } from './bill/charge-to-my-room/charge-to-my-room.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuModule } from './menu/menu.module';
import { SharedModule } from './shared/shared.module';
import { VoucherModalComponent } from './cart/voucher-modal/voucher-modal.component';
import { PaymentSheetComponent } from './cart/payment-sheet/payment-sheet.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { BillPaidComponent } from './bill-paid/bill-paid.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { RejectedItemsComponent } from './cart/rejected-items/rejected-items.component';
import { EzetapComponent } from './ezetap/ezetap.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TotalProcessingComponent } from './total-processing/total-processing.component';
import { CommentDialogComponent } from './cart/comment-dialog/comment-dialog.component';
import { PaymentLinkConfirmationComponent } from './payment-link-confirmation/payment-link-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CartComponent,
    WaiterComponent,
    BillComponent,
    SettingsComponent,
    OrderListComponent,
    PaymentMethodComponent,
    ChargeToMyRoomComponent,
    SplashScreenComponent,
    VoucherModalComponent,
    PaymentSheetComponent,
    OrderConfirmationComponent,
    BillPaidComponent,
    LanguageSelectionComponent,
    RejectedItemsComponent,
    EzetapComponent,
    PaymentFailedComponent,
    ConfirmationDialogComponent,
    TotalProcessingComponent,
    CommentDialogComponent,
    PaymentLinkConfirmationComponent,
  ],
  imports: [
    CommonModule,
    BidiModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    SentryModule,
    HttpClientModule,
    MenuModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
