export const environment = {
  production: false,

  urls: {
    api: 'https://app-7766b049-6de7-462b-9849-d3b5f8e34d5e.cleverapps.io/api',
    ni: 'https://test-network.mtf.gateway.mastercard.com/static/checkout/checkout.min.js',
    rak: 'https://rakbankpay.gateway.mastercard.com/static/checkout/checkout.min.js',
    mashreq: 'https://test-gateway.mastercard.com/static/checkout/checkout.min.js',
    credimax: 'https://credimax.gateway.mastercard.com/static/checkout/checkout.min.js',
    payfort: 'https://sbcheckout.PayFort.com/FortAPI/paymentPage',
    assets: 'https://assets.opaala.menu',
    totalprocessing: 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId='
  },
  tpl: {
    sentry: '',
  },
  firebase: {
    apiKey: 'AIzaSyCmA8rkh9Nun0DW4HHtiI2L_5IFLUoF9AE',
    authDomain: 'opaala-4087b.firebaseapp.com',
    databaseURL: 'https://opaala-4087b.firebaseio.com',
    projectId: 'opaala-4087b',
    storageBucket: 'opaala-4087b.appspot.com',
    messagingSenderId: '1033236693150',
    appId: '1:1033236693150:web:951d22040dce5753a2bb8f',
    measurementId: 'G-4WKLP9LHK7'
  }
};
