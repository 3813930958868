/* eslint-disable  @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, RendererFactory2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ApiService, GetOrder, MastercardPayload } from './api.service';
import { DataService } from './data.service';
import { DomainService } from './domain.service';
import { MasterCardService, PaymentState } from './mastercard.service';
import { environment } from '../../environments/environment';
import { DialogService } from './dialog.service';

export enum PaymentGateway {
  Payfort = 'payfort',
  RakBank = 'rak',
  NI = 'network-intl',
  Mashreq = 'mashreq',
  NGenius = 'ngenius',
  EzeTap = 'ezetap',
  Credimax = 'credimax',
  TotalProcessing = 'totalprocessing',
  Arb = 'arb',
  CCAvenue = 'cc_avenue',
  BankMuscat = 'bank-muscat',
  Etisalat = 'etisalat',
  Magnati = 'magnati',
  Checkout = 'checkout'
}

export interface TotalProcessingDetails {
  total_price_without_discount: number;
  discount_amount: number;
  total_price: number;
  url: string;
}

export interface EzeTapSessionDetails {
  orders: GetOrder[];
  transaction_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class OnlinePaymentService {
  public paymentCancelled = new EventEmitter<boolean>();
  public paymentSheetClosed: EventEmitter<boolean> = new EventEmitter();

  private restaurantId: string;
  private defaultPG: string;
  private orderIds: string[];
  private payfortURL: string;

  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private mastercardService: MasterCardService,
    private domainService: DomainService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private rendererFactory: RendererFactory2
  ) {
    this.payfortURL = environment.urls.payfort;
    this.restaurantId = this.dataService.getRestaurant()?.id;
    this.setupPG();
  }

  public startPayment(orderIds: string[]): void {
    if (this.defaultPG === PaymentGateway.Payfort) {
      const payload: MastercardPayload = {
        subdomain: this.domainService.getSubdomain(),
        order_ids: orderIds
      };
      this.startPayfortSession(payload);
    } else if (this.defaultPG === PaymentGateway.RakBank || this.defaultPG === PaymentGateway.NI
      || this.defaultPG === PaymentGateway.Mashreq || this.defaultPG === PaymentGateway.Credimax) {
      const payload: MastercardPayload = {
        subdomain: this.domainService.getSubdomain(),
        order_ids: orderIds
      };
      this.orderIds = orderIds;
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      this.mastercardService.setupScript(this.defaultPG).then(ok => {
        this.startMastercardSession(payload);
      });
    } else if (this.defaultPG === PaymentGateway.NGenius) {
      const payload: any = {
        order_ids: orderIds
      };
      this.apiService.getNGeniusSession(payload).subscribe(res => window.location.href = res.payment_link);
    } else if (this.defaultPG === PaymentGateway.EzeTap) {
      const payload = {
        order_ids: orderIds,
        device_id: this.dataService.getEzetapMachineId()
      };
      this.apiService.startEzetapSession(payload).subscribe(res => {
        this.dialogService.openEzeTap(res);
      });
    } else if (this.defaultPG === PaymentGateway.TotalProcessing) {
      const payload: any = {
        order_ids: orderIds,
        payment_type: 'totalprocessing'
      };
      this.startTPSession(payload);
    } else if (this.defaultPG === PaymentGateway.Arb) {
      const payload = {
        order_ids: orderIds
      };
      this.apiService.getArbSession(payload).subscribe(res => window.location.href = res.url);
    } else if (this.defaultPG === PaymentGateway.CCAvenue || this.defaultPG === PaymentGateway.BankMuscat) {
      const payload = {
        order_ids: orderIds,
        subdomain: this.domainService.getSubdomain()
      };
      this.apiService.getCCAvenueSession(payload).subscribe(res => {
        const renderer = this.rendererFactory.createRenderer(null, null);
        const paymentForm = renderer.createElement('div');
        renderer.setProperty(paymentForm, 'innerHTML', res.toString());
        renderer.setStyle(paymentForm, 'width', '100vw');
        renderer.setStyle(paymentForm, 'height', '100%');
        renderer.setStyle(paymentForm, 'position', 'fixed');
        renderer.setStyle(paymentForm, 'top', '0');
        renderer.setStyle(paymentForm, 'left', '0');
        renderer.setStyle(paymentForm, 'z-index', '9999');
        renderer.setStyle(paymentForm, 'background', '#fff');
        document.body.appendChild(paymentForm);
        (<HTMLFormElement>document.getElementById("nonseamless")).submit();
      });
    } else if (this.defaultPG === PaymentGateway.Etisalat) {
      const payload = {
        order_ids: orderIds
      };
      this.apiService.getEtisalatSession(payload).subscribe(res => {
        if (res.url) {
          this.dialogService.openPaymentLinkConfirmation();
        }
      });
    } else if (this.defaultPG === PaymentGateway.Magnati) {
      const payload = {
        order_ids: orderIds
      };
      this.apiService.getMagnatiSession(payload).subscribe(res => {
        if (res.url) {
          this.dialogService.openPaymentLinkConfirmation();
        }
      });
    } else if (this.defaultPG === PaymentGateway.Checkout) {
      const payload = {
        subdomain: this.domainService.getSubdomain(),
        order_ids: orderIds
      };
      this.apiService.getCheckoutSession(payload).subscribe(res => {
        window.location.href = res.url;
      });
    }
  }

  private setupPG(): void {
    if (!this.restaurantId) {
      return;
    }
    const methods = this.dataService.getPaymentMethods();
    const method = methods?.find(m => m.is_default_payment_gateway === true);
    if (!method) {
      return;
    } else {
      this.defaultPG = method.key;
    }
    this.checkEzetap();
  }

  private checkEzetap(): void {
    const machineId = this.dataService.getEzetapMachineId();
    if (machineId) {
      this.defaultPG = PaymentGateway.EzeTap;
    }
  }

  private startMastercardSession(payload: MastercardPayload): void {
    this.mastercardService.getSession(payload)
    .subscribe((session) => {
      this.mastercardService.configureSession(session);
      this.mastercardService.showLightBox();
      this.mastercardService.paymentState.subscribe((state) => this.checkPaymentState(state));
    });
  }

  private startPayfortSession(payload: any): void {
    this.apiService.getPayfortSignature(this.restaurantId, payload).subscribe(signature => {
      const payfortForm = document.createElement('form');
      payfortForm.setAttribute('method', 'post');
      payfortForm.setAttribute('action', this.payfortURL);

      for (const key of Object.keys(signature)) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', signature[key]);
        payfortForm.appendChild(input);
      }

      const serviceCommand = document.createElement('input');
      serviceCommand.setAttribute('type', 'hidden');
      serviceCommand.setAttribute('name', 'service_command');
      serviceCommand.setAttribute('value', 'TOKENIZATION');
      payfortForm.appendChild(serviceCommand);

      const lang = document.createElement('input');
      lang.setAttribute('type', 'hidden');
      lang.setAttribute('name', 'language');
      lang.setAttribute('value', 'en');
      payfortForm.appendChild(lang);

      document.body.appendChild(payfortForm);
      payfortForm.submit();
    });
  }

  private startTPSession(payload: any): void {
    this.apiService.getTPCheckout(payload).subscribe(res => {
      const id = res.id;
      const checkoutScript = document.createElement('script');
      checkoutScript.src = `${environment.urls.totalprocessing}${id}`;
      checkoutScript.type = 'text/javascript';
      document.body.appendChild(checkoutScript);

      const total = res.orders.reduce((accumulator, order) => accumulator + order.total_price_without_discount, 0);
      const totalDiscountAmount = res.orders.reduce((accumulator, order) => accumulator + order.discount_amount, 0);
      const totalAmountWithDiscount = res.orders.reduce((accumulator, order) => accumulator + order.total_price, 0);

      checkoutScript.onload = () => {
        const data: TotalProcessingDetails = {
          total_price_without_discount: total,
          discount_amount: totalDiscountAmount,
          total_price: totalAmountWithDiscount,
          url: `${environment.urls.api}/payment/total_processing`
        };
        this.dialogService.openTP(data);
      };
    });
  }

  private checkPaymentState(state: string): void {
    if (state === PaymentState.Cancelled) {
      this.paymentCancelled.emit(true);
    } else if (state === PaymentState.Error) {
      this.snackBar.open(
        this.translateService.instant('PAYMENT.ERROR'),
        this.translateService.instant('CART.GOT_IT'),
        {
          duration: 3500,
          panelClass: 'custom-snackbar'
        }
      );
    }
  }
}
