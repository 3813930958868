/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ActivableSection } from '../menu/menu.service';
import { SelectedModifier } from '../menu/modifier/modifier.component';
import { EzeTapSessionDetails } from './online-payment.service';
import { EzeTapStatusResponse } from '../ezetap/ezetap.component';
import { Promotion } from '../menu/promotions/promotions.component';
import { SafeHtml } from '@angular/platform-browser';

export interface MenuClosedResponse {
  message: string;
  image: string;
  languages: string[];
}

export interface MastercardValidationPayload {
  transaction_id: string;
  ref_code: string;
}

export interface ApplePayPayload {
  restaurant_id: string;
  applepay_url: string;
  subdomain: string;
}

export interface MastercardPayload {
  subdomain: string;
  order_ids: string[];
}

export interface CheckoutPayload {
  subdomain: string;
  order_ids: string[];
}

export interface CheckoutResponse {
  url: string;
}

export interface PayfortSignature {
  access_code: string;
  merchant_identifier: string;
  merchant_reference: string;
  signature: string;
}

export interface ApplePayPaymentPayload extends MastercardPayload {
  applepay: true;
  applepay_data: {
    token: string;
  };
}

export interface MastercardSession {
  id: string;
  updateStatus: string;
  version: string;
}

export interface MastercardOrder {
  amount: number;
  currency: string;
  description: string;
  id: string;
}

export interface MastercardResponse {
  merchant: string;
  result: string;
  session: MastercardSession;
  order: MastercardOrder;
}

export interface Schedule {
  id: string;
  week_day: number;
  start_time: string;
  end_time: string;
  timezone: string;
  price?: number;
}

/* https://app-7766b049-6de7-462b-9849-d3b5f8e34d5e.cleverapps.io/api/restaurant/payment_methods/ab9439c3-23e7-49d7-a6d0-a1ac9e526c9c */

export interface PaymentMethod {
  id: string;
  key: string;
  name: string;
  is_default_payment_gateway: boolean;
  is_online: boolean;
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/menu/ab9439c3-23e7-49d7-a6d0-a1ac9e526c9c */

export interface Modifier {
  id: string;
  name: string;
  description?: string;
  price: number;
  calories?: number;
  quantity: number;
}

export interface ModifierGroup {
  id: string;
  name: string;
  type: 'single' | 'single-compulsory' | 'multiple' | 'min-max';
  modifiers: Modifier[];
  display_type?: string;
  type_min?: number;
  type_max?: number;
  is_valid: boolean;
}

export interface Item {
  id: string;
  name: string;
  description: string;
  price: number;
  category: string;
  modifier_groups: ModifierGroup[];
  image_url: string;
  schedules: Schedule[];
  vegan: boolean;
  vegetarian: boolean;
  no_dairy: boolean;
  no_nuts: boolean;
  no_shellfish: boolean;
  no_gluten: boolean;
  calories: number;
  filters: string[];
  promotional_image_url: string;
  status: ItemStatus;
}

export interface Section {
  name: string;
  id: string;
  description: string;
  items: Item[];
  schedules: Schedule[];
  sub_sections: Section[];
  logo_url: string;
  banner_url: string;
}

export interface Menu {
  name: string;
  description: string;
  sections: ActivableSection[];
  order_message: string;
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/table/cdd752d8-bc05-4da9-9605-02ed61f9c598 */

export enum VenueType {
  Default = 'Default',
  ReadOnly = 'Read only',
}

export enum PaymentMode {
  Prepaid = 'Prepaid',
  Postpaid = 'Postpaid'
}

export enum VoucherMode {
  Single = 'single',
  Balance = 'balance',
  Multi = 'multi'
}

export enum VoucherType {
  Amount = 'amount',
  Percentage = 'percentage'
}

export enum ItemStatus {
  Enabled = 'e',
  ReadOnly = 'r',
  Unavailable = 'u'
}

export interface Restaurant {
  id: string;
  name: string;
  tagline: string;
  address: string;
  phone_number: string;
  banner_url: string;
  logo_url: string;
  directory_url: string;
  type: VenueType;
  currency: string;
  is_parent: boolean;
  banner_action: string;
  payment_mode: PaymentMode;
}

export interface Table {
  id: string;
  name: string;
  table_type: string;
  number_of_guests: number;
}

export interface Zone {
  id: string;
  name: string;
}

export interface TableAndRestaurant {
  table: Table;
  restaurant: Restaurant;
  zone: Zone;
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/config/cdd752d8-bc05-4da9-9605-02ed61f9c598 */

export interface Config {
  languages: string[];
  splash_screen: string;
  has_multiple_currencies: boolean;
  has_filters: boolean;
  menu_actions: MenuActionObject[];
  send_receipt: boolean;
  payment_methods: PaymentMethod[];
  fees: Fee[];
  grid_layout: boolean;
  promotions: Promotion[];
}

export interface MenuActionObject {
  action: MenuAction;
  config: {
    type: string | null;
    url: string | null;
  };
}

export enum MenuAction {
  Waiter = 'call_waiter',
  Orders = 'orders',
  Bill = 'bill',
  Whatsapp = 'whatsapp',
  Directory = 'directory',
  Custom = 'custom'
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/order/cdd752d8-bc05-4da9-9605-02ed61f9c598 */

export interface OrderItem {
  item: string;
  quantity: number;
  comment: string;
  modifiers: SelectedModifier[];
}
export enum OrderPaymentType {
  Room = 'room',
  Delivery = 'delivery'
}

export interface Order {
  order_items: OrderItem[];
  user_id: string;
  comment: string;
  payment_type?: string;
  delivery_inside?: boolean;
  number_of_guests?: number;
  metadata?: [index: string];
  voucher_name: string;
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/order/user/cdd752d8-bc05-4da9-9605-02ed61f9c598 */

export interface ShortRestaurant {
  id: string;
  name: string;
}

export interface ShortTable {
  id: string;
  name: string;
  table_type: string;
}

export enum OrderStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  Cancelled = 'cancelled'
}

export interface GetOrderModifier {
  currency: string;
  modifier: string;
  unit_price: number;
  total_price: number;
  unit_calories: number;
  total_calories: number;
  quantity: number;
}

export interface GetOrderItem {
  item: string;
  quantity: number;
  unit_price: number;
  total_price: number;
  unit_calories: number;
  total_calories: number;
  currency: string;
  modifiers: GetOrderModifier[];
  picked_up: boolean;
  restaurant: {
    id: string;
    name: string;
  };
}

export interface Fee {
  id: string;
  name: string;
  amount: number;
  type: VoucherType;
  displayAmount?: number;
}

export interface GetOrder {
  order_id: string;
  items: GetOrderItem[];
  status: OrderStatus;
  total_price: number;
  total_calories: number;
  created_at: string;
  updated_at: string;
  comment: string;
  currency: string;
  restaurant: ShortRestaurant;
  table: ShortTable;
  code: string;
  discount_amount: number;
  fee_amount: number;
  total_price_without_discount: number;
  payment_type: PaymentMethod;
  fees?: Fee[];
  voucher?: {
    name?: string;
  };
}

export interface Transaction {
  transaction: {
    id: string;
    created_at: string;
  };
  orders: GetOrder[];
}

export interface GetOrders {
  orders: GetOrder[];
}

/* https://app-ab81c7b4-72ed-42f0-9b4d-6e0fca007da1.cleverapps.io/api/notification */

export enum NotificationType {
  BringTheBill = 'bring_the_bill',
  CallWaiter = 'call_waiter'
}

export enum PaymentType {
  Card = 'card',
  Cash = 'cash',
  Room = 'room'
}

export interface NotificationPayload {
  type: NotificationType;
  table: string;
  user: string;
  order?: string;
  payment_type?: PaymentType;
  comment?: string;
  has_discount?: boolean;
  name?: string;
  room_number?: string;
  waiter_option_id?: string;
  voucher_name?: string;
}

export interface CreateOrderResponse {
  order_id: string;
}


export interface ItemFilter {
  id: string;
  key: string;
  name: string;
  lang: string;
}

export interface WaiterOption {
  id: string;
  key: string;
  name: string;
  lang: string;
}

export interface CurrencyRate {
  currency: string;
  rate: number;
}

export interface PickupFormField {
  key: string;
  name: string;
  type: string;
  options?: [key: string];
  max_day?: number;
  min_day?: number;
  disabled_days?: number[];
  disabled_months?: number[];
  required: boolean;
  pattern?: {
    regex: string;
    error: string;
  };
  backend?: string;
  placeholder?: string;
}

export interface OrderForm {
  content: {
    lang: PickupFormField[];
  };
  metadata?: {
    index: string;
  };
}

export interface CartConfig {
  form?: OrderForm;
  min_order: number;
}

export interface VoucherPayload {
  user_id: string;
  voucher_name?: string;
  table_id: string;
  payment_type?: string;
  order_ids?: string[];
  order_items?: OrderItem[];
}

export interface AppliedVoucher {
  amount: number;
  currency: string;
  mode: VoucherMode;
  type: VoucherType;
}

export interface VoucherResponse {
  orders: GetOrder[];
  voucher: AppliedVoucher;
  transaction_id?: string;
}

export interface MulesoftRoomCheckResponse {
  valid?: boolean;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.urls.api;
  }

  public getConfig(tableId: string): Observable<Config> {
    return this.httpClient.get<Config>(`${this.baseUrl}/config/${tableId}`);
  }

  public getMenuCached(zoneId: string): Observable<Menu> {
    return this.httpClient.get<Menu>(`${this.baseUrl}/menu/${zoneId}/cache`);
  }

  public getTableAndRestaurant(tableId: string): Observable<TableAndRestaurant> {
    return this.httpClient.get<TableAndRestaurant>(`${this.baseUrl}/table/${tableId}`);
  }

  public orderItems(tableId: string, order: Order): Observable<GetOrder> {
    return this.httpClient.post<GetOrder>(
      `${this.baseUrl}/order/table/${tableId}`,
      order
    );
  }

  public getOrder(orderId: string): Observable<GetOrder> {
    return this.httpClient.get<GetOrder>(`${this.baseUrl}/order/${orderId}`);
  }

  public cancelOrder(orderId: string): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${this.baseUrl}/order/${orderId}/cancel`,
      {}
    );
  }

  public getOrders(userId: string, restaurantId: string): Observable<GetOrders> {
    const url = `${this.baseUrl}/order/user/${userId}?restaurant_id=${restaurantId}`;
    return this.httpClient.get<GetOrders>(url);
  }

  public sendNotification(notificationPayload: NotificationPayload): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${this.baseUrl}/notification`,
      notificationPayload
    );
  }

  public getNiSession(mastercardPayload: ApplePayPaymentPayload | MastercardPayload): Observable<MastercardResponse> {
    const url = `${this.baseUrl}/payment/ni/session`;
    return this.httpClient.post<MastercardResponse>(url, mastercardPayload);
  }

  public getCheckoutSession(checkoutPayload: CheckoutPayload): Observable<CheckoutResponse> {
    const url = `${this.baseUrl}/payment/checkout`;
    return this.httpClient.post<CheckoutResponse>(url, checkoutPayload);
  }

  public submitApplePayPayfort(payload: any, restaurantId: string): Observable<MastercardResponse> {
    const url = `${this.baseUrl}/payment/payfort/applepay/${restaurantId}`;
    return this.httpClient.post<MastercardResponse>(url, payload);
  }

  public submitApplePayTP(payload: ApplePayPaymentPayload): Observable<MastercardResponse> {
    const url = `${this.baseUrl}/payment/total_processing/applepay`;
    return this.httpClient.post<MastercardResponse>(url, payload);
  }

  public validateNiTransaction(mastercardValidationPayload: MastercardValidationPayload): Observable<boolean> {
    const url = `${this.baseUrl}/payment/ni/validate`;
    return this.httpClient.post<boolean>(url, mastercardValidationPayload);
  }

  public getItemFilters(restaurantId: string): Observable<ItemFilter[]> {
    return this.httpClient.get<ItemFilter[]>(`${this.baseUrl}/item-filter/${restaurantId}`);
  }

  public getWaiterOptions(restaurantId: string): Observable<WaiterOption[]> {
    const url = `${this.baseUrl}/waiter-option/${restaurantId}`;
    return this.httpClient.get<WaiterOption[]>(url);
  }

  public getCurrencies(restaurantId: string): Observable<CurrencyRate[]> {
    const url = `${this.baseUrl}/restaurant/currencies/${restaurantId}`;
    return this.httpClient.get<CurrencyRate[]>(url);
  }

  public getApplePaySession(applePayPayload: ApplePayPayload): Observable<any> {
    const url = `${this.baseUrl}/payment/applepay/session`;
    return this.httpClient.post<any>(url, applePayPayload);
  }

  public getPickupForm(zoneId: string): Observable<PickupFormField[]> {
    const url = `${this.baseUrl}/pickup-form/${zoneId}`;
    return this.httpClient.get<PickupFormField[]>(url);
  }

  public getPayfortSignature(restaurantId: string, payload: MastercardPayload): Observable<PayfortSignature> {
    const url = `${this.baseUrl}/payment/payfort/signature/${restaurantId}`;
    return this.httpClient.post<PayfortSignature>(url, payload);
  }

  public getNGeniusSession(payload: any): Observable<any> {
    const url = `${this.baseUrl}/payment/ngenius/session`;
    return this.httpClient.post(url, payload);
  }

  public getCartConfig(restaurantId: string): Observable<CartConfig> {
    const url = `${this.baseUrl}/config/cart/${restaurantId}`;
    return this.httpClient.get<CartConfig>(url);
  }

  public getTransaction(transactionId: string): Observable<Transaction> {
    const url = `${this.baseUrl}/transaction/${transactionId}`;
    return this.httpClient.get<Transaction>(url);
  }

  public applyPrepaidCheckout(restaurantId: string, payload: VoucherPayload): Observable<VoucherResponse> {
    const url =`${this.baseUrl}/checkout/prepaid/${restaurantId}`;
    return this.httpClient.post<any>(url, payload);
  }

  public applyPostpaidCheckout(restaurantId: string, payload: VoucherPayload): Observable<VoucherResponse> {
    const url =`${this.baseUrl}/checkout/postpaid/${restaurantId}`;
    return this.httpClient.post<any>(url, payload);
  }

  public confirmPostpaidCheckout(restaurantId: string, payload: VoucherPayload): Observable<VoucherResponse> {
    const url =`${this.baseUrl}/checkout/postpaid/${restaurantId}`;
    return this.httpClient.put<any>(url, payload);
  }

  public startEzetapSession(payload: any): Observable<EzeTapSessionDetails> {
    const url = `${this.baseUrl}/payment/ezetap`;
    return this.httpClient.post<any>(url, payload);
  }

  public getEzetapStatus(deviceId: string, transactionId: string): Observable<EzeTapStatusResponse> {
    return this.httpClient
      .get<EzeTapStatusResponse>(`${this.baseUrl}/payment/ezetap?device_id=${deviceId}&transaction_id=${transactionId}`);
  }

  public cancelEzetapPayment(device_id: string, transaction_id: string): Observable<EzeTapStatusResponse> {
    const payload = {
      device_id,
      transaction_id
    };
    return this.httpClient
      .put<EzeTapStatusResponse>
        (`${this.baseUrl}/payment/ezetap`, payload);
  }

  public getTPCheckout(payload: any): Observable<any> {
    const url = `${this.baseUrl}/payment/total_processing`;
    return this.httpClient.post<any>(url, payload);
  }

  public getArbSession(payload): Observable<any> {
    const url = `${this.baseUrl}/payment/arb`;
    return this.httpClient.post<any>(url, payload);
  }

  public getEtisalatSession(payload): Observable<any> {
    const url = `${this.baseUrl}/payment/etisalat`;
    return this.httpClient.post<any>(url, payload);
  }

  public getMagnatiSession(payload): Observable<any> {
    const url = `${this.baseUrl}/payment/magnati`;
    return this.httpClient.post<any>(url, payload);
  }

  public getCCAvenueSession(payload): Observable<SafeHtml> {
    const url = `${this.baseUrl}/payment/ccavenue`;
    return this.httpClient.post(url, payload, {
      responseType: 'text',
      headers: new HttpHeaders().set('Content-Type', 'text/html'),
    });
  }

  public checkMulesoftRoom(restaurantId: string, roomNumber: number): Observable<MulesoftRoomCheckResponse> {
    return this.httpClient.get(`${this.baseUrl}/mulesoft/${restaurantId}/room/${roomNumber}`);
  }
}
